<template>
  <div>
    <base-search @search="search" />
    <el-table
      :data="tableData"
      :header-cell-style="headerCellStyle"
      class="table"
      @sort-change="sortChange"
    >
      <el-table-column prop="productCode" label="基础产品ID" min-width="180"> </el-table-column>
      <el-table-column prop="productName" label="基础产品名称" min-width="180"> </el-table-column>
      <el-table-column prop="salePrice" label="售价(元)" sortable="custom"> </el-table-column>
      <el-table-column prop="num" label="本店售卖数量" sortable="custom"> </el-table-column>
      <el-table-column prop="isOnShelf" label="产品状态">
        <template slot-scope="{ row }">
          <span>{{ row.isOnShelf === 1 ? '上架' : '下架' }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page.sync="page.pageNumber"
        :page-size="page.pageSize"
        background
        :page-sizes="[10, 20, 30, 40]"
        layout="prev, pager, next, sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import baseSearch from './base-search.vue'
import { getStoreProductAdditionList } from '@/api/product-management.js'
export default {
  components: {
    baseSearch,
  },
  data() {
    return {
      tableData: [],
      params: {},
      page: {
        pageNumber: 1,
        pageSize: 10,
      },
      total: 0,
      sort: {
        sortField: '',
        sortType: '',
      },
      headerCellStyle: {
        background: '#F5F7FA',
      },
    }
  },
  methods: {
    // 排序
    sortChange({ prop, order }) {
      if (order) {
        this.sort.sortField = prop
        this.sort.sortType = order === 'descending' ? 'desc' : 'asc'
      } else {
        this.sort.sortField = ''
        this.sort.sortType = ''
      }
      this.getData()
    },
    // 重置参数
    reset() {
      const { page, params, sort } = this.$options.data()
      this.page = page
      this.sort = sort
      this.params = params
    },
    // 搜索
    search(params) {
      this.page = this.$options.data().page
      this.params = { ...this.params, ...params }
      this.getData()
    },
    // 获取表格数据
    getData() {
      const params = {
        data: { ...this.params },
        ...this.page,
        ...this.sort,
      }
      getStoreProductAdditionList(params).then((res) => {
        if (!res.success) return
        const { content, total } = res.data
        this.tableData = content
        this.total = Number(total)
      })
    },
    handleSizeChange(size) {
      this.page.pageNumber = 1
      this.page.pageSize = size
      this.getData()
    },
    handleCurrentChange(number) {
      this.page.pageNumber = number
      this.getData()
    },
  },
}
</script>

<style lang="less" scoped>
.table {
  border-left: 1px solid #f5f7fa;
  border-right: 1px solid #f5f7fa;
}
</style>
