<template>
  <div class="productManagement">
    <div class="tab">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-if="hasPermission('SUPPLY_BASE_PRODUCT')"
          label="基础产品"
          name="base"
        ></el-tab-pane>
        <el-tab-pane
          v-if="hasPermission('SUPPLY_SETS_PRODUCT')"
          label="套系产品"
          name="sets"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="table">
      <component :is="components[activeName]" :ref="activeName" />
    </div>
  </div>
</template>

<script>
import baseTable from './table/base-table.vue'
import setsTable from './table/sets-table.vue'
import { hasPermission } from '@/utils/buttonAuthority.js'
export default {
  data() {
    return {
      activeName: 'base',
      components: {
        base: baseTable,
        sets: setsTable,
      },
    }
  },
  mounted() {
    if (!this.hasPermission('SUPPLY_BASE_PRODUCT')) this.activeName = 'sets'
    this.getData()
  },
  methods: {
    hasPermission,
    getData() {
      this.$nextTick(() => {
        this.$refs[this.activeName].getData()
      })
    },
    handleClick() {
      this.getData()
    },
  },
}
</script>

<style lang="less" scoped>
.productManagement {
  .tab {
    background: #fff;
    margin-bottom: 9px;
    ::v-deep .el-tabs__header {
      margin: 0 20px;
    }
    ::v-deep .el-tabs__nav-wrap::after {
      content: none;
    }
  }
  .table {
    background: #fff;
    padding: 0 20px 20px;
  }
}
</style>
