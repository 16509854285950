<template>
  <div class="sets-table">
    <sets-search @search="search" />
    <el-table
      :data="tableData"
      :header-cell-style="headerCellStyle"
      class="table"
      @sort-change="sortChange"
    >
      <el-table-column prop="productCode" label="套系产品ID" min-width="180"> </el-table-column>
      <el-table-column prop="productName" label="套系产品名称" min-width="180"> </el-table-column>
      <el-table-column prop="salePrice" label="售价(元)" min-width="100" sortable="custom">
      </el-table-column>
      <el-table-column prop="num" label="本店售卖数量" min-width="180" sortable="custom">
      </el-table-column>
      <el-table-column prop="shootsNum" label="本店实拍次数" min-width="180">
        <template slot-scope="{ row }">
          <el-link
            v-if="row.shootsNum"
            type="primary"
            :underline="false"
            @click="toServiceOrder(row)"
            >{{ row.shootsNum }}</el-link
          >
          <span v-else class="disable" style="color: #c0c4cc">{{ row.shootsNum }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="shootsStoreNum" label="可拍门店" min-width="180">
        <template slot-scope="{ row }">
          <el-tooltip
            class="item"
            effect="dark"
            placement="top"
            popper-class="sets-table-tooltip"
            :disabled="row.shootsStoreNum === 0"
          >
            <div slot="content">
              <div v-for="item in row.storeList" :key="item.storeId" class="storeName">
                {{ item.storeName }}
              </div>
            </div>
            <span>{{ row.shootsStoreNum }}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="isOnShelf" label="产品状态" fixed="right">
        <template slot-scope="{ row }">
          <span>{{ row.isOnShelf === 1 ? '上架' : '下架' }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page.sync="page.pageNumber"
        :page-size="page.pageSize"
        background
        :page-sizes="[10, 20, 30, 40]"
        layout="prev, pager, next, sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import setsSearch from './sets-search.vue'
import { getStoreProductSaleListt } from '@/api/product-management.js'
export default {
  components: {
    setsSearch,
  },
  data() {
    return {
      tableData: [],
      params: {},
      page: {
        pageNumber: 1,
        pageSize: 10,
      },
      total: 0,
      sort: {
        sortField: '',
        sortType: '',
      },
      headerCellStyle: {
        background: '#F5F7FA',
      },
    }
  },
  methods: {
    // 跳转到服务单列表
    toServiceOrder(row) {
      if (!row.shootsNum) return
      this.$router.push({
        name: 'list',
        params: { serviceIds: JSON.stringify(row.serviceIds) },
      })
    },
    // 排序
    sortChange({ prop, order }) {
      if (order) {
        this.sort.sortField = prop
        this.sort.sortType = order === 'descending' ? 'desc' : 'asc'
      } else {
        this.sort.sortField = ''
        this.sort.sortType = ''
      }
      this.getData()
    },
    // 重置参数
    reset() {
      const { page, params, sort } = this.$options.data()
      this.page = page
      this.sort = sort
      this.params = params
    },
    // 搜索
    search(params) {
      this.page = this.$options.data().page
      this.params = { ...this.params, ...params }
      this.getData()
    },
    // 获取表格数据
    getData() {
      const params = {
        data: { ...this.params },
        ...this.page,
        ...this.sort,
      }

      getStoreProductSaleListt(params).then((res) => {
        if (!res.success) return
        const { content, total } = res.data
        this.tableData = content
        this.total = Number(total)
      })
    },
    handleSizeChange(size) {
      this.page.pageNumber = 1
      this.page.pageSize = size
      this.getData()
    },

    handleCurrentChange(number) {
      this.page.pageNumber = number
      this.getData()
    },
  },
}
</script>

<style>
.sets-table-tooltip {
  max-width: none;
}
</style>

<style lang="less" scoped>
.table {
  border-left: 1px solid #f5f7fa;
  border-right: 1px solid #f5f7fa;
}
.storeName {
  margin-bottom: 6px;
  white-space: nowrap;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
}
</style>
