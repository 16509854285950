<template>
  <div class="base-search">
    <el-form :model="form" class="demo-form-inline">
      <el-row :gutter="32">
        <el-col :span="6">
          <div class="search-item">
            <span class="search-item-label">套系产品ID</span>
            <el-input
              v-model="form.productCode"
              size="small"
              placeholder="请输入套系产品ID"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="search-item">
            <span class="search-item-label">套系产品名称</span>
            <el-input
              v-model="form.productName"
              size="small"
              placeholder="请输入套系产品名称"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="search-item">
            <span class="search-item-label">产品状态</span>
            <el-select v-model="form.isOnShelf" size="small" placeholder="请选择" clearable>
              <el-option label="上架" value="1"> </el-option>
              <el-option label="下架" value="0"> </el-option>
            </el-select>
          </div>
        </el-col>

        <el-col :span="6">
          <div class="search-item-handle">
            <el-button type="primary" size="small" icon="el-icon-search" @click="search">
              查询
            </el-button>
            <el-button size="small" icon="el-icon-refresh-left" @click="reset"> 重置 </el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        productName: '',
        isOnShelf: '',
        productCode: '',
      },
    }
  },
  methods: {
    // 搜索
    search() {
      const form = JSON.parse(JSON.stringify(this.form))
      this.$emit('search', form)
    },
    // 重置
    reset() {
      this.form = this.$options.data(this).form
      this.search()
    },
  },
}
</script>

<style lang="less" scoped>
.base-search {
  padding: 20px 0;
  .search-item {
    display: flex;
    align-items: center;
    background: #f5f6f9;
    border-radius: 4px 4px 4px 4px;
    padding: 0 12px;
    &-label {
      font-size: 14px;
      color: #999999;
      margin-right: 24px;
      white-space: nowrap;
    }

    /deep/ .el-select {
      width: 100%;
    }
    /deep/ .el-input__inner {
      border: none;
      background: #f5f6f9;
      font-size: 14px;
      padding: 0;
    }

    /deep/ .el-range-input {
      background: #f5f6f9;
      font-size: 14px;
    }

    /deep/ .el-date-editor .el-range-separator {
      width: unset;
      line-height: 32px;
    }

    /deep/ .el-input {
      input::placeholder {
        font-size: 14px;
        color: #999999;
        line-height: 20px;
      }
    }

    /deep/ .el-date-editor {
      justify-content: space-between;

      input::placeholder {
        font-size: 14px;
        color: #999999;
      }
    }

    /deep/.el-select__caret .el-input__icon .el-icon-arrow-up {
      color: #9a9d9e;
    }
  }
  .search-item-time {
    .item-time-icon {
      flex: 1;
      text-align: right;
      color: #9a9d9e;
      cursor: pointer;
    }
    /deep/ .el-input__icon {
      display: none;
    }
  }
  .search-item-handle {
    display: flex;
    align-items: center;
  }

  ::v-deep {
    .el-button--primary {
      background: #2861e2;
      border-color: #2861e2;
    }
    .el-button--primary:focus,
    .el-button--primary:hover {
      background: #5381e8;
      border-color: #5381e8;
      color: #fff;
    }
  }
}
</style>
