import request from '@/utils/request'

// 产品管理列表-基础产品
export function getStoreProductAdditionList(data) {
  return request({
    url: 'product-web-api/supplier/product/getStoreProductAdditionList',
    method: 'POST',
    data,
  })
}

// 产品管理列表-套系产品
export function getStoreProductSaleListt(data) {
  return request({
    url: 'product-web-api/supplier/product/getStoreProductSaleList',
    method: 'POST',
    data,
  })
}
